
.stat-block {
  border-radius: 20px;
  padding: 10px 20px;
  min-height: 100px;
  display: flex;
  align-items: center;
  color: #fff;
  background-position: bottom -2em right -5em !important;
  &.path1 {
    background: url("../assets/paths/path1.svg") #fc7234 no-repeat;
  }
  &.path2 {
    background: url("../assets/paths/path2.svg") #23b1de no-repeat;
  }
  &.path3 {
    background: url("../assets/paths/path3.svg") #f9004e no-repeat;
  }
  .title {
    color: #fff;
  }
  @media only screen and (max-width: 900px) {
    background-position: bottom -2em right -10em !important;
  }
}
.container {
  padding-top: 10px !important;
}

.add-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  button {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    padding: 0 !important;
    &:hover {
      box-shadow: 0 4px 9px rgba(0, 0, 0, 0.25), 0 4px 4px rgba(0, 0, 0, 0.22);
    }
  }
}

@media only screen and (max-width: 750px) {
  .add-btn {
    bottom: 20px;
    right: 20px;
    button {
      width: 50px;
      height: 50px;
    }
  }
}

.news {
  margin-top: 40px;
  background: #fff;
  padding: 10px 40px;
  border-radius: 10px;
  img {
    max-width: 150px;
    margin-top: -30px;
  }

  .column {
    display: flex;
    align-items: center;
    &.pulled-right {
      justify-content: flex-end;
    }
  }
}

.search-input {
  max-width: 30%;
}

@media only screen and (max-width: 1050px) {
  .illustration {
    width: 0;
    padding: 0;
    img {
      display: none;
    }
  }
}

@media only screen and (max-width: 800px) {
  .search-input {
    max-width: 70%;
  }
}

@media only screen and (max-width: 450px) {
  .search-input {
    max-width: 100%;
  }
}

.discord-logo {
  color: #1dc0f4;
}

.icon.hoverable:hover {
  transition: all 0.2s;
  color: #fc7234;
}

.card {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}
