
.card {
  height: 100%;
  padding: 15px;
  background-color: #fff;
  border-radius: 20px;
  min-height: 60px;
  border: 2px solid #e2e9e9;
  border-bottom-width: 4px;
  display: flex;
  align-items: center;
  .word-count {
    display: none;
    position: absolute;
    bottom: 5px;
    right: 6px;
    background: #209cee;
    color: #fff;
    font-weight: 600;
    border-radius: 15px;
    padding: 2px 7px;
    font-size: 0.7rem;
  }
  &.checked,
  &:hover {
    border-color: #209cee;
    cursor: pointer;
    .word-count {
      display: block;
    }
  }
  &:active {
    border-bottom-width: 2px;
    border-color: #e2e9e9;
  }
  .list-language {
    font-size: 0.9rem;
  }
  .list-language,
  .list-name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.card-dotted {
  background: #fff;
  border: 2px dashed #e2e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  .icon {
    color: #e2e9e9;
  }
  &:hover {
    border: 2px dashed #209cee;
    color: #209cee;
  }
}
.dropdown {
  float: right;
}
.trigger-btn {
  font-size: 0.8rem;
}

.card {
  .checkbox.control {
    font-family: arial;
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 16px;
    height: 25px;
    margin-top: -4px;
    margin-left: 10px;
  }
}

.dark {
  background: red;
}
